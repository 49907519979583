<template>
  <div>
    <LoadingBanner
      v-bind:title="userName" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="row [ mt-3 ]" v-if="uploadDisabled || isError">
      <div class="col-md-12">
        <form enctype="multipart/form-data" novalidate v-cloak>
            <div class="alert alert-primary text-center [ m-0 p-0 ]" @drop.prevent="addFile" @dragover.prevent>
              <input type="file" multiple class="input-file"
                v-bind:name="uploadFileName"
                v-bind:disabled="isLoading" 
                v-on:change="addFile($event.target)">
                  <p class="[ m-4 ]">
                    Drag your file(s) here to begin<br> or click to browse
                  </p>
            </div>
          </form>
      </div>
    </div>

    <div v-if="!uploadDisabled">
      <div class="row [ mt-4 ]">
        <div class="col-md-12">
          <h5>Selected files</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="list-group">
            <li class="list-group-item" v-for="file in files" v-bind:key="file.name">
              <span>{{ file.name }} ({{ file.size }} kb)</span> 
              <span class="pull-right">
                <LoadingPanel v-if="isLoading && !file.isError"></LoadingPanel>
                 <span v-if="!isLoading && !file.isError" style="color:green;">
                  Success <i class="fa fa-check-circle" aria-hidden="true"></i>
                </span>
                <span v-if="file.isError" style="color:red;">
                  Failed <i class="fa fa-exclamation-triangle" aria-hidden="true" title="Upload failed for this file."></i>
                </span>
              </span>
            </li>
            </ul>
        </div>
      </div>
    </div>

     <div class="row [ mt-5 ]" v-if="isError" >
      <div class="col-md-12">
        <div class="alert alert-danger" role="alert">
          An error occured while uploading files. Please try again
        </div>
      </div>
    </div>

     <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import LoadingPanel from '@/components/LoadingPanel'
import { setSuccessMessage, setErrorMessage } from '@/utils'
import { default as usersAPI } from '@/api/UsersAPI'
import { default as assetsAPI } from '@/api/AssetsAPI'

export default {
  name: 'AdminShareUploadContainer',

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner,
    'LoadingPanel': LoadingPanel
  },

  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      user: null,
      uploadFileName: 'documents',
      files:[],
      isError: false
    }
  },

  mounted () {
    this.init()
  },

  computed: {
    userName: function() {
      return (this.user ? this.user.firstName + ' ' + this.user.lastName + ' - Share a document' : 'Loading')
    },

    uploadDisabled() {
      return this.files.length === 0;
    }
  },

  methods: {
    init: function() {
      usersAPI
        .getByUserId(this.getUserIdParam())
        .then((user) => this.user = user)
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isUserInitialLoadComplete = true)
    },

    addFile(e) {
      // Passed event is called dataTranser for drag & drop, and e.files for input button click
      let droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.files;
      
      if(!droppedFiles) return;
 
      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      });

      this.upload()
    },

    upload() {
      this.isLoading = true
      this.isError = false
      let formData = new FormData()

      this.files.forEach((f,x) => {
        formData.append('file'+(x+1), f);
      });
      
      formData.append('userId', this.getUserIdParam())

      assetsAPI
        .share(formData)
        .then((assets) => {
          assets.forEach(asset => {
            if (asset.amazonUploadFailed) {
              for (var i = 0; i < this.files.length; i++) {
                if (this.files[i].name === (asset.displayName + asset.fileExtension)) {
                  this.files[i].isError = true
                  this.isError = true
                }
              }
            }
          })
          
          if (!this.isError) {
            this.$router.push({ name: 'adminShare', params: { userId: this.user.id }})
          } else {
            setErrorMessage(this, 'errorMessage')
          }
        })
        .catch((response) => {
          setErrorMessage(this, 'errorMessage')
          this.files.forEach(file => file.isError = true)
        })
        .finally(() => this.isLoading = false)
    },

    getUserIdParam: function() {
      return this.$route.params.userId
    }    
  }
}
</script>

<style scoped>
  .input-file {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    cursor: pointer;
  }
</style>
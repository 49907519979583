import Vue from 'vue'
const APP_CONFIG_URL = 'ApplicationConfiguration/'

export default {
    get () {
      return Vue.http.get(APP_CONFIG_URL)
        .then((response) => { return response.body })
    },

    insert (appConfig) {
      return Vue.http.post(APP_CONFIG_URL, appConfig)
        .then((response) => { return response.body })
    }
}
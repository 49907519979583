<template>
  <div>
    <LoadingBanner
      v-bind:title="'Sent Emails'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="row mt-3">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="col-auto">
              <DateInput 
                v-bind:date="searchStartDate"
                v-bind:name="'searchStartDateComponent'"
                v-on:dateSelected="update('searchStartDate', $event)">
              </DateInput>
            </div>
            <div class="col-auto">
              <label class="[ text-dark my-2  mx-2 ]">to</label>
            </div>
            <div class="col-auto">
              <DateInput 
                v-bind:date="searchEndDate"
                v-bind:name="'searchEndDateComponent'"
                v-on:dateSelected="update('searchEndDate', $event)">
              </DateInput>
            </div>
            <div class="col-auto">
              <a name="search" id="search" class="btn btn-primary" v-on:click="searchEmails()">Search</a>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <table class="table table-resonsive-sm" v-if="emailIds.length > 0">
          <thead>
            <tr>
              <th class="text-center">Send Success</th>
              <th>Sent On</th>
              <th>To</th>
              <th>Subject</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(id) in emailIds" v-bind:key="id">
              <td class="text-center">
                <i class="fa fa-check text-success" v-if="emails[id].isSentSuccess" aria-hidden="true"></i>
                <i class="fa fa-times text-danger" v-else area-hiden="true"></i>
              </td>
              <td>{{ toLocalTime(emails[id].utcSentTime) }}</td>
              <td>{{ emails[id].toEmail }}</td>
              <td>{{ emails[id].subject }}</td>
            </tr>
          </tbody>
        </table>
         <div class="alert alert-info" role="alert" v-if="emails.length === 0 && !isLoading">
          <strong>
            No sent emails found for date range.
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import DateInput from '@/components/DateInput'
import { utcToLocalTimeString, localTimeToUtc, setSuccessMessage, setErrorMessage } from '@/utils'
import { default as emailsAPI } from '@/api/EmailsAPI'
import dayjs from 'dayjs'

export default {
    name: 'AdminEmailsContainer',

    components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner,
    'DateInput': DateInput
  },

  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      emailIds: [],
      emails: {},
      searchStartDate: dayjs().subtract(7, 'day').startOf('day').toDate(),
      searchEndDate: dayjs().endOf('day').toDate()
    }
  },

  mounted () {
    this.init()
  },

  computed: {
    
  },

  methods: {
    init: function () {
      this.searchEmails()
    },

    searchEmails: function () {
      this.emailIds = []
      this.emails = {}
      this.isLoading = true
      
      emailsAPI
        .find(localTimeToUtc(this.searchStartDate), localTimeToUtc(this.searchEndDate))
        .then((emails) => {
          emails.forEach(email => {
            this.emailIds.push(email.id)
            this.emails[email.id] = email
          })
        })
        .catch(() => setErrorMessage('errorMessage'))
        .finally(() => this.isLoading = false)
    },

    update: function(key, value) {
      this[key] = value;
    },

    toLocalTime(dateTime) {
      return utcToLocalTimeString(dateTime)
    }
  }
}
</script>

<style>

</style>
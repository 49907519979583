<template>
  <div>
    <LoadingBanner
      v-bind:title="'Portal Settings'"
      v-bind:isLoading="isLoading"></LoadingBanner>
    <div class="row [ mt-5 ]">
      <div class="col-md-12">
        <form @submit.prevent="onSave" class="form-horizontal">
          <div class="form-group">
            <label class="font-weight-bold" for="email">File History Days Back <span class="text-muted font-weight-light">Number of days back to show documents for.</span></label>
            <input v-model="appConfig.fileHistoryDaysBack" name="daysBack" id="daysBack" class="form-control" aria-describedby="daysBack" />
          </div>
          <div class="form-group">
            <LoadingPanel v-if="isSaving"></LoadingPanel>
            <button v-else type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import LoadingBanner from '@/components/LoadingBanner'
import LoadingPanel from '@/components/LoadingPanel'
import MessageDisplay from '@/components/MessageDisplay'
import { default as appConfigAPI } from '@/api/AppConfigAPI'
import { setSuccessMessage, setErrorMessage } from '@/utils'

export default {
  name: 'AdminSettingsContainer',

  components: {
    'LoadingBanner': LoadingBanner,
    'LoadingPanel': LoadingPanel,
    'MessageDisplay': MessageDisplay
  },

  mounted () {
    this.onLoad()
  },

  data () {
    return {
      isLoading: false,
      isSaving: false,
      errorMessage: null,
      successMessage: null,
      appConfig: {}
    }
  },

  methods: {
    onLoad() {
      this.isLoading = true
      appConfigAPI
        .get()
        .then((appConfig) => this.appConfig = appConfig)
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isLoading = false)
    },

    onSave() {
      this.isSaving = true
      appConfigAPI
        .insert(this.appConfig)
        .then(() => setSuccessMessage(this, 'successMessage'))
        .catch(() => setErrorMessage(this, 'errorMessage'))
        .finally(() => this.isSaving = false)
    }
  }
}
</script>
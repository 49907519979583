<template>
  <div>
    <DatePicker v-bind:value="date" 
        v-bind:name="name" 
        v-bind:format="format"
        v-bind:input-class="inputClass"
        calendar-class="'calendarInput'"
        v-on:selected="dateSelected"></DatePicker> 
  </div>
</template>

<script>
export default {
  name: 'DateInput',

  props: [
    'date',
    'name'
  ],

  data () {
    return {
      format: 'dd MMM yyyy',
      inputClass: 'form-control'
    }
  },

  methods: {
    dateSelected: function (date) {
      this.$emit('dateSelected', date)
    }
  }
}
</script>

<style>
</style>
